import React from "react";
import { netural400, neturalBlack, noticeBanner } from "../../utils/colors";
import { IoTrashOutline } from "react-icons/io5";

const StyledText = ({ children, type, onClick }) => {
  const styleMap = {
    link: styles.link,
    placeholder: styles.placeholder,
    default: {},
  };

  return (
    <span style={styleMap[type]} onClick={onClick}>
      {children}
    </span>
  );
};

const parseMessage = (message) => {
  const parts = [];
  const regex = /\[([^\]]+)\]|\{\{(.*?)\}\}/g;
  let match;
  let lastIndex = 0;

  while ((match = regex.exec(message)) !== null) {
    if (lastIndex < match.index) {
      parts.push({ text: message.slice(lastIndex, match.index), type: "text" });
    }

    if (match[1]) {
      parts.push({ text: match[1], type: "link" });
    } else if (match[2]) {
      parts.push({ text: match[2], type: "placeholder" });
    }

    lastIndex = regex.lastIndex;
  }

  if (lastIndex < message.length) {
    parts.push({ text: message.slice(lastIndex), type: "text" });
  }

  return parts;
};

const DiscountAppliedBanner = ({ message, onClick }) => {
  const parsedMessage = parseMessage(message);

  return (
    <div style={styles.container}>
      <div>
        {parsedMessage.map((part, index) => {
          if (part.type === "link") {
            return (
              <StyledText key={index} type="link">
                {part.text}
              </StyledText>
            );
          } else if (part.type === "placeholder") {
            return (
              <StyledText key={index} type="placeholder">
                {part.text}
              </StyledText>
            );
          } else if (part.type === "text") {
            return (
              <StyledText key={index} type="text">
                {part.text}
              </StyledText>
            );
          }
          return <span key={index}>{part.text} </span>;
        })}
      </div>
      <IoTrashOutline
        color={neturalBlack}
        size={30}
        style={{ marginLeft: "1%" }}
        onClick={() => onClick && onClick()}
      />
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "white", // Light peach background
    borderRadius: "12px", // Rounded corners
    padding: "16px", // Inner spacing
    fontSize: "14px", // Text size
    lineHeight: "20px", // Line height for better readability
    color: netural400, // Neutral 500
    display: "inline-block", // Wrap content
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
    marginTop: "3%",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    border: "solid 1px #dadada",
  },
  link: {
    color: netural400, // Primary Orange
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "14px",
  },
  placeholder: {
    color: netural400, // Success Green
    fontWeight: "bold",
    fontSize: "14px",
  },
};

export default DiscountAppliedBanner;
