import { gql } from "@apollo/client";

export const FETCH_PRODUCT_WITH_FILTER_SORT = gql`
  query fetchProductsWithFilterAndSort(
    $filterBy: InputFilters
    $sortBy: String
    $searchedKeyword: String
    $category: String
    $subCategories: [InputSubCategories]
    $pageNumber: Int
    $pageSize: Int
  ) {
    fetchProductsWithFilterAndSort(
      filterBy: $filterBy
      sortBy: $sortBy
      searchedKeyword: $searchedKeyword
      category: $category
      subCategories: $subCategories
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      products {
        _id
        title
        price
        finalTotal
        companyName
        medias {
          height
          key
          mediaType
          uri
          width
        }
      }
      totalCount
      hasNext
    }
  }
`;

export const FETCH_PRODUCT_DETAILS_BY_ID = gql`
  query fetchItemDetailsById($itemId: String, $type: String) {
    fetchItemDetailsById(itemId: $itemId, type: $type) {
      _id
      businessId
      businessVerificationStatus
      companyName
      description
      discount
      finalTotal
      itemType
      medias {
        height
        key
        mediaType
        uri
        width
      }
      price
      productDetails
      quantity
      quantityLimitation
      subscriptionName
      taxRate
      title
      providerName
      totalRating
      totalReview
      variantCombinations {
        combination
        discount
        finalTotal
        price
        quantityLimitation
        quantity
        sku
      }
      variants {
        variantId
        variantOptions
        variantType
      }
      reviews {
        product_image
        product_name
        item_id
        review_date
        review_description
        review_rating
        review_title
        userName
        user_id
      }
      autoship
      sellingPlan {
        _id
        planName
        discount
        frequencyType
        minimumCycle
      }
    }
  }
`;

export const FETCH_REVIEWS = gql`
  query fetchReviews($item_id: String) {
    fetchReviews(item_id: $item_id) {
      _id
      item_id
      review_title
      review_description
      review_date
      userName
      user_id
      review_rating
      product_image
      product_name
    }
  }
`;

export const ADD_TO_CART = gql`
  mutation addToCart($itemId: String, $quantity: Int, $type: String) {
    addToCart(itemId: $itemId, quantity: $quantity, type: $type) {
      success
      message
    }
  }
`;

export const CATEGORY_SEARCH = gql`
  query categorySearch($query: String) {
    categorySearch(query: $query) {
      _id
      name
      interestsCount
      isChecked
      subCategories {
        _id
        name
      }
      subCategoryCount
    }
  }
`;

export const FETCH_SUBCATEGORIES_BY_NAME = gql`
  query fetchSubCategoriesByCategoryName($categoryName: String) {
    fetchSubCategoriesByCategoryName(categoryName: $categoryName) {
      _id
      name
    }
  }
`;

export const FETCH_CATEGORIES_BASED_ON_PRODUCTS = gql`
  query fetchCategoriesBasedOnProducts {
    fetchCategoriesBasedOnProducts {
      _id
      name
      productCount
      subCategoryCount
    }
  }
`;

export const VALIDATE_PROMOTIONAL_CODE = gql`
  query validatePromotionalCode($promotionalCodeInputs: PromotionalCodeInputs) {
    validatePromotionalCode(promotionalCodeInputs: $promotionalCodeInputs) {
      message
      isValid
      promotionalCode {
        stripeId
        code
        type
        discountPercentage
        discountedAmount
        finalAmount
      }
    }
  }
`;

export const FETCH_BUSINESS_TERMS_AND_POLICY_INFO_BY_ID = gql`
  query fetchBusinessTermsAndPrivacyById($businessId: String) {
    fetchBusinessTermsAndPrivacyById(businessId: $businessId) {
      success
      error
      data {
        termsAndConditions
        privacyPolicy
      }
    }
  }
`;

export const FETCH_CHECK_OUT_TOPUP_BANNER = gql`
  query fetchCheckoutTopUpBanner(
    $userId: String
    $sellerId: String
    $checkoutInfo: CheckoutInfo
    $requestFrom: String
  ) {
    fetchCheckoutTopUpBanner(
      userId: $userId
      sellerId: $sellerId
      checkoutInfo: $checkoutInfo
      requestFrom: $requestFrom
    ) {
      message
      details {
        topUpAmount
        discount
        finalTotal
        additionalPointsTax
      }
    }
  }
`;

export const FETCH_CHECK_OUT_SUCCESS_BANNER = gql`
  query fetchCheckoutSuccessBanner($type: String, $finalTotal: Float) {
    fetchCheckoutSuccessBanner(type: $type, finalTotal: $finalTotal) {
      message
      details {
        finalTotal
      }
    }
  }
`;

export const FETCH_CHECKOUT_REMOVE_MODEL = gql`
  query fetchCheckoutRemoveModel($discountAmount: Float, $type: String) {
    fetchCheckoutRemoveModel(discountAmount: $discountAmount, type: $type) {
      details {
        discount
      }
      message
    }
  }
`;
