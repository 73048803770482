import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomLabel from "../shared/label/custom-label.component";
import CustomButton from "../button/button.component";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { isEmptyObject } from "../../utils/constants";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import {
  setUserCart,
  updateCart,
  updateUserServiceCart,
} from "../../store/cart/cart.action";

const PaymentModal = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { businessId, promotionalCode, price, itemType, cartValue } = props;
  const bannerPrice = {
    ...price,
    businessId: businessId,
  };
  const handlePayOnClick = async () => {
    setIsLoading(true);
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsLoading(false);
      return;
    }
    if (promotionalCode && !isEmptyObject(promotionalCode)) {
      let coupon = promotionalCode;
      const added_promocode = {
        ...cartValue,
        coupon,
      };
      dispatch(setUserCart([added_promocode]));
    }
    if (itemType === "service") {
      dispatch(updateUserServiceCart(bannerPrice));
    } else {
      dispatch(updateCart(bannerPrice));
    }

    const { error } = await stripe.confirmPayment({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${
          process.env.REACT_APP_WEBSITE_URL
        }/order/paymentstatus?id=${businessId}&${new URLSearchParams(
          window.location.search
        ).toString()}`,
      },
    });

    if (error) {
      localStorage.removeItem("promotionCode");
      dispatch(updateCart(bannerPrice));
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setIsLoading(false);
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setIsLoading(false);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
    >
      <Modal.Header
        closeButton
        style={{
          borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
          paddingLeft: "3%",
          paddingRight: "3%",
          backgroundColor: "white",
          borderRadius: "12px",
          paddingBottom: "0px",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          <CustomLabel>Payment</CustomLabel>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          marginBottom: "2%",
          paddingLeft: "3%",
          paddingRight: "3%",
          paddingTop: "1%",
        }}
      >
        <PaymentElement />
        <CustomButton
          isValid={!stripe}
          processing={isLoading}
          title={"Pay"}
          buttonType="inverted"
          handleButtonClick={handlePayOnClick}
        />
        {errorMessage && <div>{errorMessage}</div>}
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
