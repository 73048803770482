import { gql } from "@apollo/client";

export const FETCH_SERVICES_WITH_FILTER_SORT = gql`
  query fetchServicesWithFilterAndSort(
    $filterBy: InputServiceFilters
    $location: Location
    $sortBy: String
    $searchedKeyword: String
    $pageNumber: Int
    $pageSize: Int
  ) {
    fetchServicesWithFilterAndSort(
      filterBy: $filterBy
      location: $location
      sortBy: $sortBy
      searchedKeyword: $searchedKeyword
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      services {
        _id
        title
        price
        finalTotal
        discount
        medias {
          uri
          height
          key
          width
        }
        providerName
      }
      totalCount
      hasNext
    }
  }
`;

export const FETCH_SERVICE_DETAILS_BY_ID = gql`
  query fetchItemDetailsById($itemId: String, $type: String) {
    fetchItemDetailsById(itemId: $itemId, type: $type) {
      _id
      userId
      businessId
      businessVerificationStatus
      description
      discount
      finalTotal
      itemType
      medias {
        height
        key
        mediaType
        uri
        width
      }
      price
      subscriptionName
      taxRate
      title
      bookingRestriction
      providerName
      totalRating
      totalReview
      serviceOption
      serviceType
      serviceLocation
      expired
      startDate
      endDate
      duration {
        hours
        minutes
      }
      reviews {
        product_image
        product_name
        item_id
        review_date
        review_description
        review_rating
        review_title
        userName
        user_id
      }
    }
  }
`;

export const GET_SCHEDULE = gql`
  query getSchedule($serviceId: String, $date: String) {
    getSchedule(serviceId: $serviceId, date: $date) {
      endTime
      startTime
      available
    }
  }
`;

export const GET_AVAILABLE_SEATS = gql`
  query getAvailableSlotSpots(
    $serviceId: ID!
    $selectedDate: String!
    $selectedStartTime: String!
    $selectedEndTime: String!
  ) {
    getAvailableSlotSpots(
      serviceId: $serviceId
      selectedDate: $selectedDate
      selectedStartTime: $selectedStartTime
      selectedEndTime: $selectedEndTime
    )
  }
`;

export const VERIFY_AVAILABLE_SLOT_SPOTS = gql`
  mutation verifyAvailableSlotSpots(
    $serviceId: ID!
    $selectedDate: String!
    $selectedStartTime: String!
    $selectedEndTime: String!
    $spots: Int!
  ) {
    verifyAvailableSlotSpots(
      serviceId: $serviceId
      selectedDate: $selectedDate
      selectedStartTime: $selectedStartTime
      selectedEndTime: $selectedEndTime
      spots: $spots
    ) {
      message
      remainingCapacity
      success
    }
  }
`;

export const FETCH_SHIPPING = gql`
  query fetchShipping {
    fetchShipping {
      address
      firstName
      lastName
      email
      unitNumber
      activeAddress
      key
    }
  }
`;
