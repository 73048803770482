import { Modal } from "react-bootstrap";
import { netural400, neturalBlack } from "../../utils/colors";
import CustomLabel from "../shared/label/custom-label.component";

const TermsAndPrivacyModel = (props) => {
  const { title, body } = props;
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        className="login-modal-style"
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
            paddingLeft: "3%",
            paddingRight: "3%",
            backgroundColor: "white",
            borderRadius: "12px",
            paddingBottom: "0px",
            marginBottom: "2%",
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            <CustomLabel
              style={{
                fontSize: "20px",
                fontWeight: "500",
                color: neturalBlack,
              }}
            >
              {title}
            </CustomLabel>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            marginBottom: "2%",
            paddingLeft: "3%",
            paddingRight: "3%",
            paddingTop: "3%",
            borderTop: "solid 1px #dadada",
          }}
        >
          <CustomLabel
            style={{
              fontSize: "16px",
              fontWeight: "normal",
              color: netural400,
            }}
          >
            {body}
          </CustomLabel>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TermsAndPrivacyModel;
