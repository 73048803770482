import { CART_ACTION_TYPES } from "./cart.types";

export const CART_INITIAL_STATE = {
  cart: {},
  service: {},
  autoship: {},
  cartCount: 0,
};

export const cartReducer = (state = CART_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CART_ACTION_TYPES.SET_CART_COUNT:
      return {
        ...state,
        cartCount: payload,
      };
    case CART_ACTION_TYPES.SET_CART:
      return {
        ...state,
        cart: payload,
      };
    case CART_ACTION_TYPES.SET_SERVICE_CART:
      return {
        ...state,
        service: payload,
      };
    case CART_ACTION_TYPES.UPDATE_SERVICE_CART:
      return {
        ...state,
        service:
          state.service.businessId === payload.businessId
            ? { ...state.service, ...payload } // Update banner if businessId matches
            : { ...state.service }, // Add payload properties if not matching
      };
    case CART_ACTION_TYPES.SET_AUTOSHIP_CART:
      return {
        ...state,
        autoship: payload,
      };
    case CART_ACTION_TYPES.RESET_CART_COUNT:
      return {
        ...state,
        cartCount: 0,
      };
    case CART_ACTION_TYPES.RESET_SERVICE_CART:
      return {
        ...state,
        service: {},
      };
    case CART_ACTION_TYPES.RESET_AUTOSHIP_CART:
      return {
        ...state,
        autoship: {},
      };
    case CART_ACTION_TYPES.INCREASE_CART_COUNT:
      return {
        ...state,
        cartCount: Math.max(state.cartCount + payload, 0), // Ensure non-negative count
      };
    case CART_ACTION_TYPES.DECREASE_CART_COUNT:
      return {
        ...state,
        cartCount: Math.max(state.cartCount - payload, 0), // Prevent negative count
      };
    case CART_ACTION_TYPES.UPDATE_CART:
      return {
        ...state,
        cart: state.cart.map((item) => {
          if (item.businessId === payload.businessId) {
            // Spread the payload properties individually into the matched item
            const { businessId, ...restPayload } = payload;
            return {
              ...item,
              ...restPayload, // Add individual properties to the cart item
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
};
