export const CART_ACTION_TYPES = {
  SET_CART_COUNT: "cart/SET_CART_COUNT",
  SET_CART: "cart/SET_CART",
  SET_SERVICE_CART: "cart/SET_SERVICE_CART",
  UPDATE_SERVICE_CART: "cart/UPDATE_SERVICE_CART",
  SET_AUTOSHIP_CART: "cart/SET_AUTOSHIP_CART",
  RESET_CART_COUNT: "cart/RESET_CART_COUNT",
  RESET_SERVICE_CART: "cart/RESET_SERVICE_CART",
  RESET_AUTOSHIP_CART: "cart/RESET_AUTOSHIP_CART",
  INCREASE_CART_COUNT: "cart/INCREASE_CART_COUNT",
  DECREASE_CART_COUNT: "cart/DECREASE_CART_COUNT",
  UPDATE_CART: "cart/UPDATE_CART",
};
