import { Button, Modal } from "react-bootstrap";
import CustomLabel from "../shared/label/custom-label.component";
import {
  errorRed,
  netural400,
  neturalBlack,
  noticeBanner,
} from "../../utils/colors";

const StyledText = ({ children, type, onClick }) => {
  const styleMap = {
    link: styles.link,
    placeholder: styles.placeholder,
    default: {},
  };

  return (
    <span style={styleMap[type]} onClick={onClick}>
      {children}
    </span>
  );
};

const parseMessage = (message) => {
  const parts = [];
  const regex = /\[([^\]]+)\]|\{\{(.*?)\}\}/g;
  let match;
  let lastIndex = 0;

  while ((match = regex.exec(message)) !== null) {
    if (lastIndex < match.index) {
      parts.push({ text: message.slice(lastIndex, match.index), type: "text" });
    }

    if (match[1]) {
      parts.push({ text: match[1], type: "link" });
    } else if (match[2]) {
      parts.push({ text: match[2], type: "placeholder" });
    }

    lastIndex = regex.lastIndex;
  }

  if (lastIndex < message.length) {
    parts.push({ text: message.slice(lastIndex), type: "text" });
  }

  return parts;
};

const GlobalModel = (props) => {
  const { title, bodyMessage, onRemoveClick } = props;
  const parsedMessage = parseMessage(bodyMessage);

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="login-modal-style"
        centered
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "none",
            padding: "1.5rem 2rem",
            paddingBottom: 0,
            backgroundColor: "white",
            borderRadius: "24px",
          }}
        />
        <Modal.Body
          style={{
            padding: "0px 2rem 0px",
            marginBottom: "24px",
            marginTop: "24px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomLabel
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: neturalBlack,
              }}
            >
              {title}
            </CustomLabel>
            <div style={styles.container}>
              {parsedMessage.map((part, index) => {
                if (part.type === "link") {
                  return (
                    <StyledText
                      key={index}
                      type="link"
                      // onClick={() => onClick && onClick(part.text)}
                    >
                      {part.text}
                    </StyledText>
                  );
                } else if (part.type === "placeholder") {
                  return (
                    <StyledText key={index} type="placeholder">
                      {part.text}
                    </StyledText>
                  );
                }
                return <span key={index}>{part.text}</span>;
              })}
            </div>
          </div>
          <Button
            onClick={onRemoveClick}
            style={{
              backgroundColor: errorRed,
              borderColor: errorRed,
              borderRadius: "8px",
              padding: "0.75rem 2rem",
              fontSize: "16px",
              fontWeight: "bold",
              width: "100%",
              marginTop: "24px",
            }}
          >
            Remove{" "}
            <span role="img" aria-label="sad face">
              🥺
            </span>
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    borderRadius: "12px", // Rounded corners
    fontSize: "16px", // Text size
    lineHeight: "20px", // Line height for better readability
    fontFamily: "Arial, sans-serif", // Font
    color: netural400, // Neutral 500
    display: "inline-block", // Wrap content
    marginTop: "3%",
  },
  link: {
    color: netural400, // Primary Orange
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: 16,
  },
  placeholder: {
    color: netural400, // Success Green
    fontWeight: "bold",
    fontSize: 16,
  },
};

export default GlobalModel;
