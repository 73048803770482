import { gql } from "@apollo/client";

export const FETCH_CART = gql`
  query fetchCart {
    fetchCart {
      _id
      userId
      cartCount
      items {
        businessId
        companyName
        shippingCost
        taxRate
        businessLogo
        type
        items {
          _id
          title
          price
          discount
          finalTotal
          companyName
          businessId
          businessLogo
          medias {
            key
            uri
            width
            height
            mediaType
            uri_thumbnail
          }
          description
          productDetails
          quantity
          quantityLimitation
          variantCombination
        }
      }
    }
  }
`;

export const FETCH_CART_COUNT = gql`
  query fetchCartCounts($profileId: String) {
    fetchCartCount(profileId: $profileId)
  }
`;

export const UPDATE_QUANTITY = gql`
  mutation updateQuantity(
    $quantity: String
    $itemId: String
    $businessId: String
    $variantCombination: String
  ) {
    updateQuantity(
      quantity: $quantity
      itemId: $itemId
      businessId: $businessId
      variantCombination: $variantCombination
    ) {
      success
    }
  }
`;

export const REMOVE_CART_ITEM = gql`
  mutation removeCartItem(
    $itemId: String
    $businessId: String
    $variantCombination: String
  ) {
    removeCartItem(
      itemId: $itemId
      businessId: $businessId
      variantCombination: $variantCombination
    ) {
      success
    }
  }
`;

export const VALIDATE_QUANTITY = gql`
  mutation validateQuantity($itemQuantity: [ItemQuantity]) {
    validateQuantity(itemQuantity: $itemQuantity) {
      success
      itemAvaibility {
        availableQuantity
        itemName
      }
    }
  }
`;

export const VALIDATE_QUANTITY_LIMITATION = gql`
  mutation validateQuantityLimitation(
    $itemQuantityLimitation: [ItemQuantityLimitation]
  ) {
    validateQuantityLimitation(
      itemQuantityLimitation: $itemQuantityLimitation
    ) {
      success
      itemAvaibility {
        availableQuantity
        itemName
      }
    }
  }
`;

export const CREATE_PAYMENT_INTENT = gql`
  query createPaymentIntent($userInfo: UserData, $price: String) {
    createPaymentIntent(userInfo: $userInfo, price: $price) {
      paymentIntent
      ephemeralKey
      customer
      publishableKey
    }
  }
`;

export const UPDATE_CONSUMER_TRANSACTION_DETAILS = gql`
  mutation updateConsumerTransactionDetails(
    $cart: InputCompany
    $price: String
    $discount: String
    $type: String
  ) {
    updateConsumerTransactionDetails(
      cart: $cart
      price: $price
      discount: $discount
      type: $type
    ) {
      success
      transactionId
    }
  }
`;

export const REMOVE_CART_COMPANY = gql`
  mutation removeCartCompany($cart: CartCompany) {
    removeCartCompany(cart: $cart) {
      success
    }
  }
`;

export const UPDATE_ITEM_QUANTITY = gql`
  mutation updateItemQuantity($itemQuantity: [ItemQuantity]) {
    updateItemQuantity(itemQuantity: $itemQuantity) {
      success
    }
  }
`;

export const VALIDATE_ORDER_INFORMATION = gql`
  mutation validateOrder($address: String, $itemQuantity: [ItemQuantity]) {
    validateOrder(address: $address, itemQuantity: $itemQuantity) {
      success
      validationResponse {
        address
        itemAvaibility {
          availableQuantity
          itemName
        }
      }
    }
  }
`;
export const FETCH_HISTORY_DATA = gql`
  query fetchHistoryDetail($transactionId: String) {
    fetchHistoryDetail(transactionId: $transactionId) {
      _id
      description
      receiptURL
      createdAt
      type
      amount
      discount
      fullName
      companyName
      itemCount
      orderInfo {
        orderDate
        orderId
        scheduleDate
        serviceDuration
        serviceMethod
      }
      productInfo {
        id
        name
        providerId
        providerName
        media
        price
        rank
        duration
        profile
      }
      orderSummary {
        itemInfo {
          _id
          title
          price
          uri
          quantity
          checkReview
          variantCombination
        }
        sellingPlan {
          _id
          planName
          discount
          frequencyType
          minimumCycle
          sellingPlanDiscount
          billingCycle
        }
        coupon {
          discountPercentage
          discountedAmount
          type
        }
        bannerDiscount {
          serviceFees
          additionalDiscount
          additionalPointsTax
        }
        subtotal
        discount
        totalDiscount
        taxRate
        taxAmount
        total
        bookedSpots
        shippingCost
      }
      deliveryAddress {
        streetName
        cityName
        stateAndZipCode
        country
      }
      businessDetails {
        businessId
        companyName
        email
        phoneNumber
      }
    }
  }
`;

export const FETCH_ORDER_DETAILS = gql`
  query fetchOrderDetails($transactionId: String) {
    fetchOrderDetails(transactionId: $transactionId) {
      userInfo {
        id
        userName
        contactNumber
        email
      }
      orderInfo {
        orderId
        orderDate
        scheduleDate
        serviceDuration
        serviceMethod
      }
      type
      paymentSummary {
        itemInfo {
          _id
          title
          price
          uri
          quantity
          checkReview
          variantCombination
        }
        itemPrice
        subscriptionPoints
        shippingCost
        platformFees
        platformFeesRate
        taxRate
        taxAmount
        transactionFees
        totalAmount
        netIncome
        bookedSpots
      }
      deliveryAddress {
        streetName
        cityName
        stateAndZipCode
        country
      }
      fullName
    }
  }
`;

export const CREATE_PRODUCT_SUBSCRIPTION = gql`
  mutation createProductSubscription(
    $createProductSubscriptionInputs: CreateProductSubscriptionInputs
  ) {
    createProductSubscription(
      createProductSubscriptionInputs: $createProductSubscriptionInputs
    ) {
      customer
      ephemeralKey
      paymentIntent
      publishableKey
    }
  }
`;
